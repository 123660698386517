import React from "react";

const OpeningTiming = () => {
  return (
    <div className="text-center py-14 bg-secondary">
      <div className="container">
        <h2 className="title-secundary leading-none text-primary mb-4 flex flex-col gap-2">
          <span>2024 Summer Hours</span>
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 w-fit mx-auto">
          <div className="w-full text-white-yale w-fit">
            <p className="text-2xl">May 15 - September 29</p>
            <div className="flex flex-col lg:flex-row gap-1 lg:gap-2">
              <p>Wednesday - Sunday</p>
              <p>(10am - 5pm)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpeningTiming;
